<script setup></script>

<template>
  <div class="our-team">
    <div class="our-team-info">
      <div class="our-team-header">
        <div class="left-part">
          <div class="title nowrap">
            <h1>Our Team</h1>
          </div>
          <div class="join-us">
            <span>Want to join us?</span>
            <a
              href="https://helixpay.ph/careers#view-openings"
              target="_blank"
              class="positions-link"
              >View Open Positions</a
            >
          </div>
        </div>
        <div class="right-part">
          A talented team of builders, engineers and entrepreneurs in the
          Philippines
        </div>
      </div>
    </div>

    <!-- <div
        v-motion
        class="employees"
        :initial="{
          opacity: 0,
          y: 100,
        }"
        :visibleOnce="{
          opacity: 1,
          y: 0,
          transition: {
            delay: 100,
            stiffness: 200,
            damping: 90,
            mass: 3,
          },
        }"
      >
        <div class="mates">
          <div class="employee">
            <img src="/img/koger-min.png" alt="" />
            <h5>Andrew</h5>
            <h5>Co-Founder & CEO</h5>
          </div>
          <div class="employee">
            <img src="/img/asis-min.png" alt="" />
            <h5>Jeff</h5>
            <h5>Co-Founder & CTO</h5>
          </div>
          <div class="employee">
            <img src="/img/aninipot-min.png" alt="" />
            <h5>Princess</h5>
            <h5>Business<br />Development</h5>
          </div>
          <div class="employee">
            <img src="/img/loberiano-min.png" alt="" />
            <h5>Leah</h5>
            <h5>Compliance Officer</h5>
          </div>
          <div class="employee">
            <img src="/img/camatog-min.png" alt="" />
            <h5>Ryan</h5>
            <h5>Engineering</h5>
          </div>
          <div class="employee">
            <img src="/img/gamboa-min.png" alt="" />
            <h5>Jillian</h5>
            <h5>Head of Sales</h5>
          </div>
        </div>
        <div class="mates">
          <div class="employee">
            <img src="/img/carino-min.png" alt="" />
            <h5>Dexter</h5>
            <h5>Engineering</h5>
          </div>
          <div class="employee">
            <img src="/img/maimot-min.png" alt="" />
            <h5>Kenneth</h5>
            <h5>Finance</h5>
          </div>
          <div class="employee">
            <img src="/img/delara-min.png" alt="" />
            <h5>Kenneth</h5>
            <h5>Engineering</h5>
          </div>
          <div class="employee">
            <img src="/img/nava-min.png" alt="" />
            <h5>Gerald</h5>
            <h5>Office Admin</h5>
          </div>
          <div class="employee">
            <img src="/img/lim-min.png" alt="" />
            <h5>Zasha</h5>
            <h5>Business<br />Development</h5>
          </div>
          <div class="employee">
            <img src="/img/pineda-min.png" alt="" />
            <h5>Jonan</h5>
            <h5>Engineering</h5>
          </div>
        </div>
        <div class="mates">
          <div class="employee">
            <img src="/img/mirandilla-min.png" alt="" />
            <h5>Issa</h5>
            <h5>Engineering</h5>
          </div>
          <div class="employee">
            <img src="/img/vidal-min.png" alt="" />
            <h5>Ginger</h5>
            <h5>Business<br />Development</h5>
          </div>
          <div class="employee">
            <img src="/img/ogayon-min.png" alt="" />
            <h5>Jason</h5>
            <h5>Engineering</h5>
          </div>
          <div class="employee">
            <img src="/img/deleon-min.png" alt="" />
            <h5>Marielle</h5>
            <h5>Graphic Designer</h5>
          </div>
        </div>
      </div> -->

    <img src="/img/org_chart_portrait.png" class="org-chart org-chart-mobile" />

    <div class="org-chart-wrapper">
      <img src="/img/org_chart.png" class="org-chart org-chart-desktop" />
    </div>
  </div>
</template>

<style scoped>
.our-team {
  width: 100%;
  margin-top: 2rem;
  padding-top: 4rem;
  padding-bottom: 4rem;
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.our-team-info {
  width: 71rem;
  margin: auto;
}

.our-team-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.our-team-header h1 {
  font-weight: 700;
}

.our-team-info {
  width: 90%;
  margin: auto;
}

.left-part {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-right: 2rem;
}

.join-us {
  font-weight: bold;
}

.right-part {
  width: 100%;
  max-width: 20rem;
}

.employees {
  margin-top: 4rem;
}

.mates {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 3rem;
}

.mates:nth-child(2),
.mates:nth-child(3),
.mates:nth-child(4),
.mates:nth-child(5),
.mates:nth-child(6),
.mates:nth-child(7) {
  margin-top: 3rem;
}

.employee {
  text-align: center;
}

.employee h5 {
  font-weight: 700;
  font-size: 0.75rem;
}

.employee h5:nth-child(2) {
  margin-top: 1rem;
}

.employee h5:nth-child(3) {
  color: #25a4e1;
  margin-top: 0.3rem;
}

.employee img {
  width: 90%;
  border-radius: 50%;
}

.org-chart-wrapper {
  display: none;
}

.org-chart-desktop {
  border-radius: 12px;
}

@media (min-width: 1200px) {
  .org-chart-wrapper {
    padding: 0 2rem;
    display: block;
  }

  .org-chart-mobile {
    display: none;
  }
}

@media (max-width: 900px) {
  .left-part {
    gap: 0;
  }

  .left-part {
    display: grid;
  }

  .right-part {
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    max-width: auto;
  }

  .right-part p {
    margin-top: 1rem;
  }

  .our-team-header {
    display: grid;
  }
}

.org-chart {
  width: 100%;
}

.nowrap {
  white-space: nowrap;
}

.positions-link {
  color: #25a4e1;
  cursor: pointer;
  text-decoration: none;
  display: block;
}

.positions-link:hover {
  text-decoration: underline;
}

@media (min-width: 600px) {
  .positions-link {
    margin-left: 1rem;
    display: inline;
  }
}
</style>
